import React from 'react'
import Layout from '../components/layout'

class Erstkontakt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Erstkontakt | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Erstkontakt</h2>
              </header>
              <div>
                <p>
                  <span>
                    Wenn Menschen nach psychotherapeutischer Begleitung suchen,
                    unabhängig ob auf Rat eines Arztes oder auf eigenen Wunsch,
                    ist häufig der Auslöser in einer aktuellen, konkreten
                    Krankheits-, Krisen- oder Konfliktsituation zu finden, auch
                    wenn die Problematik nicht selten bereits eine längere
                    Vorgeschichte hat.
                  </span>
                </p>
                <p>
                  <span>
                    Daher ist der Wunsch nach einem schnellen Beginn aus meiner
                    Sicht sehr nachvollziehbar und ein Warten über mehrere
                    Wochen und Monaten eigentlich nicht zumutbar. Deshalb habe
                    ich mich entschieden, keine Warteliste zu führen und biete
                    Ihnen nach Möglichkeit kurzfristig einen Therapieplatz an.
                    Ich bemühe mich, mit Ihnen beim telefonischen Erstkontakt
                    einen Termin für ein Erstgespräch möglichst innerhalb
                    von&nbsp; 2-4 Wochen zu finden.
                  </span>
                </p>
                <p>
                  <span>
                    Wenn ein Erstgespräch erst innerhalb von 4 -8 Wochen
                    realisierbar erscheint, notiere ich Ihre Kontaktdaten und
                    biete Ihnen dann telefonisch oder per mail den nächsten
                    freien Termin an.
                  </span>
                  <span>
                    Wenn ich zu den Zeiten, zu denen Sie kommen können, absehbar
                    keine Termine anbieten kann, bespreche ich das mit Ihnen
                    direkt im telefonischen Erstkontakt und nenne Ihnen auf
                    Wunsch Adressen zur Therapieplatzvermittlung.
                  </span>
                </p>
                <p>
                  <span>
                    Wenn sich im Erstgespräch bzw. im Rahmen der ersten 5
                    probatorischen Sitzungen herausstellt, dass eine
                    Psychotherapie sinnvoll und notwendig ist und Sie sich für
                    mich als Ihre Psychotherapeutin entscheiden, kann die
                    Psychotherapie ohne weitere Wartezeit fortgesetzt werden.
                  </span>
                </p>
                <p>
                  <span>
                    Dieses Vorgehen führt allerdings auch dazu, dass ich
                    regelmäßige wöchentliche Termine vor allem zu Therapiebeginn
                    nicht verlässlich zusagen kann und Sie um Flexibilität
                    bitten muss.
                  </span>
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Erstkontakt
